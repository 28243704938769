@import "_mixins";

.pill {    
    display: inline-block;
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 100px;
    box-shadow: none;
    padding: 8px 16px;
    color: #fff;
    outline: none;
    transition: all 0.2s;
    margin: 2.5px;
    text-transform: uppercase;

    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    
    @include respond-to(sm) {
        padding: 10px 20px;
        font-size: 14px;
        line-height: 21px;
        margin: 0 5px;
    }


    &:hover, &:focus {
        background: rgba(0,0,0,0.3);
        box-shadow: none;
        outline: none;
        cursor: pointer;
    }
}