@import "_mixins";

.timer-container {
    display: block;
    margin: 0 auto;
    max-width: 330px;
    padding: 20px;
    
    @include respond-to(sm) {
        max-width: 620px;
    }
    @include respond-to(md) {
        max-width: 685px;
    }

}

.timer {
    position: relative;
    display: block;
    border: 5px solid #fff;
    color: #fff;
    opacity: 0.5;
    text-align: center;
    border-radius: 500px;
    padding: 20px 20px;
    transition: all 0.2s;
    overflow: hidden;
    
    @include respond-to(sm) {
        border-width: 7px;
    }

    &:hover, &:focus {
        opacity: 1;

        .timer__notification {
            opacity: 0;
            z-index: -1;
        }
    }

    // .timer--active
    &--active {
        opacity: 1;
    }

    // .timer--finished
    &--finished {
        border-color: #D03B3B;
        background: #D03B3B;
        color: #fff;
        opacity: 1;

        .timer__notification {
            opacity: 1;
            z-index: 2;
        }
    }

    // .timer--warning
    &--warning {
        border-color: #D03B3B;
        color: #D03B3B;
        opacity: 1;
    }

    // .timer__text
    &__text {
        display: block;
        font-size: 48px;
        font-weight: 500;
        line-height: normal;
        transition: all 0.2s;
        opacity: 1;

        @include respond-to(sm) {
            font-size: 90px;
        }
        @include respond-to(md) {
            font-size: 108px;
        }
        
        input {
            background: transparent;
            border: 0;
            box-shadow: none;
            color: inherit;
            font-family: 'Poppins';
            font-size: 48px;
            font-weight: 500;
            padding: 0;
            margin: 0;
            outline: none;
            text-align: center;
            width: 100%;
            height: 100%;

            @include respond-to(sm) {
                font-size: 90px;
            }
            @include respond-to(md) {
                font-size: 108px;
            }

            &:-ms-input-placeholder,
            &::-ms-input-placeholder,
            &::placeholder {
                color: inherit;
            }
        }
    }

    // .timer__notification
    &__notification {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.2s;
        opacity: 0;
        text-align: center;
        background: #D03B3B;
        display: flex;
        align-items: center;
        justify-items: center;

        // .timer__notification__text
        &__text {
            display: block;
            width: 100%;
            text-align: center;
            font-family: 'Poppins';
            font-size: 48px;
            font-weight: 500;
            
            @include respond-to(sm) {
                font-size: 90px;
            }
            @include respond-to(md) {
                font-size: 108px;
            }
        }

        // .timer__notification__subtext
        &__subtext {
            position: absolute;
            bottom: 7.5px;

            display: block;
            width: 100%;
            text-align: center;
            font-family: 'Poppins';
            font-size: 11px;
            font-weight: 500;

            opacity: 0.85;
            
            @include respond-to(sm) {
                font-size: 12px;
                bottom: 20px;
            }
            @include respond-to(md) {
                font-size: 14px;
            }
        }
    }
}