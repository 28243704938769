// breaks
$break-xs: 375px;
//$break-xs: 420px;
$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;
$break-xl: 1600px;
$break-xxl: 1775px;
$default-screen: 1920px;


@mixin respond-to($media) {
    @if $media == xs {
        @media only screen and (min-width: $break-xs) {
            @content;
        }
    }
    @else if $media == lessthan-xs {
        @media only screen and (max-width: $break-xs) {
            @content;
        }
    }
    @else if $media == only-xs {
        @media only screen and (max-width: $break-sm - 1) {
            @content;
        }
    }
    @else if $media == lessthan-sm {
        @media only screen and (max-width: $break-sm - 1) {
            @content;
        }
    }
    @else if $media == sm {
        @media only screen and (min-width: $break-sm) {
            @content;
        }
    }
    @else if $media == only-sm {
        @media only screen and (min-width: $break-sm) and (max-width: $break-md - 1) {
            @content;
        }
    }
    @else if $media == lessthan-md {
        @media only screen and (max-width: $break-md - 1) {
            @content;
        }
    }
    @else if $media == md {
        @media only screen and (min-width: $break-md) {
            @content;
        }
    }
    @else if $media == only-md {
        @media only screen and (min-width: $break-md) and (max-width: $break-lg - 1) {
            @content;
        }
    }
    @else if $media == lessthan-lg {
        @media only screen and (max-width: $break-lg - 1) {
            @content;
        }
    }
    @else if $media == lg {
        @media only screen and (min-width: $break-lg) {
            @content;
        }
    }
    @else if $media == only-lg {
        @media only screen and (min-width: $break-lg) and (max-width: $break-xl - 1) {
            @content;
        }
    }
    @else if $media == lessthan-xl {
        @media only screen and (max-width: $break-xl - 1) {
            @content;
        }
    }
    @else if $media == xl {
        @media only screen and (min-width: $break-xl) {
            @content;
        }
    }
    @else if $media == xxl {
        @media only screen and (min-width: $break-xxl) {
            @content;
        }
    }
    @else if $media == ds {
        @media only screen and (min-width: $default-screen + 1) {
            @content;
        }
    }
    @else if $media == print {
        @media print {
            @content;
        }
    }
}
