@import "_mixins";

.heading {
    display: block;
    margin: 0 0 25px;
    color: inherit;
    line-height: 1.55;

    @include respond-to(sm) {
        margin: 0 0 30px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    // .heading--lv1
    &--lv1 {
        font-size: 28px;
        font-weight: 900;
        line-height: 1.7;

        @include respond-to(sm) {
            font-size: 36px;
        }
    }

    // .heading--lv2
    &--lv2 {
        font-size: 24px;
        font-weight: 600;

        @include respond-to(sm) {
            font-size: 28px;
        }
    }

    // .heading--lv3
    &--lv3 {
        font-size: 20px;
        font-weight: 600;

        @include respond-to(sm) {
            font-size: 24px;
        }
    }
    
    // .heading--lv4
    &--lv4 {
        font-size: 18px;
        font-weight: 600;

        @include respond-to(sm) {
            font-size: 22px;
        }
    }
    
    // .heading--lv5
    &--lv4 {
        font-size: 16px;
        font-weight: 600;

        @include respond-to(sm) {
            font-size: 20px;
        }
    }
}

// .text-box
.text-box {
    p, li {
        font-size: 14px;
        line-height: 1.65;
        
        @include respond-to(sm) {
            font-size: 16px;
            line-height: 1.65;
        }
    }
}